<template>
  <Modal :activeModal="isActiveModal" @closeModal="closeModal" :widthModal="500">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          {{ entidade.cod  }} - {{ entidade.name }}
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      
      <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loading">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-card-text v-if="!loading">
        <v-simple-table
          dense
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                </th>
                <th class="text-right">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><strong>Email:</strong></td>
                <td class="text-right">{{ entidade.email }}</td>
              </tr>
              <tr>
                <td><strong>Telefone:</strong></td>
                <td class="text-right">{{ entidade.phone }}</td>
              </tr>
              <tr>
                <td><strong>Endereço:</strong></td>
                <td class="text-right">{{ entidade.address }}</td>
              </tr>
              <tr>
                <td><strong>CEP:</strong></td>
                <td class="text-right">{{ entidade.cep }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions v-if="!loading">
        <v-col>
          <v-btn 
            :href="'https://wa.me/' + this.entidade.phoneWithoutCaracteres"
            :target="'_blank'"
            :color="variables.colorPrimary"
            class="br-btn br-btn-whatsapp">Whatsapp</v-btn>
          <v-btn
            :href="'tel:' + this.entidade.phoneWithoutCaracteres"
            :target="'_blank'"
            :color="variables.colorPrimary"
            class="br-btn ml-3">Ligar</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import variables from '@/assets/styles/helpers/_variables.scss'
const namespaceStore = 'historicoVendas'

export default {
  name: 'ModalDadosEntidade',
  props: {
    isActiveModal: {
      type: Boolean,
      default: false
    },

    entityId: {
      type: Number,
      required: true
    }
  },

  components: {
    Modal: () => import('@/views/components/modal'),
  },
  data: () => ({
    activeModal: false,
    loading: false,
    entidade: {
      name: '',
      cep: '',
      cod: '',
      phone: '',
      phoneWithoutCaracteres: '',
      address: '',
      email: '',
    }
  }),

  watch: {
    'isActiveModal' (val) {
      if (val) {
        this.loading = true
        this.getEntities({ id: this.entityId })
          .then(res => {
            const { name, cep, cod, phone, address, city, state, neighborhood, number, email } = res.resultMap[0]
            this.entidade = {
              name,
              cep,
              cod,
              phone,
              phoneWithoutCaracteres: `55${phone.replace(/\D/g, '')}`,
              email,
              address: `${address}, ${neighborhood} , ${number}, ${city} - ${state}`
            }
          })
          .finally(() => this.loading = false)
      }
    }
  },
  
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters(namespaceStore, ['item']),
    ...mapGetters(namespaceStore, ['item']),
    variables: () => variables,
  },
  mounted () {},
  methods: {
    ...mapActions(namespaceStore, []),
    ...mapActions('entities', ['getEntities']),
    openModal () {
      this.activeModal = true
    },

    closeModal () {
      this.$emit('closeModal', this.isActiveModal)
    },

    getEntidade () {
      this.entityId
    },

    openLink () {
       window.open(`https://wa.me/${this.entidade.phoneWithoutCaracteres}`, '_blank')
    }
  },
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/modal-cadastro';

  .br-btn-whatsapp {
    background-color: #00c70f !important;
    border-color: #00c70f !important;
  }
</style>